import React, { useEffect, useLayoutEffect, useState } from "react";
import { CarouselVideoPlayer } from "../VedioPlayer/VideoPlayer";
import { CarouselImageDisplay } from "../Images/Image";
import {Icon} from '@iconify-icon/react'
import {CarouselIcons} from "../Icons/Icons";
import './Carousel.css';

const Carousel = ({ media, initialIndex, onClose, flag, setDeleteId, setInitialCarouselIndex }) => {

    const [currentIndex, setCurrentIndex] = useState(initialIndex);
    const userType = sessionStorage.getItem('userType');
    const [currentMedia, setCurrentMedia] = useState(media[currentIndex])

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % media.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + media.length) % media.length);
    };

    useEffect(() => {
        setCurrentMedia(media[currentIndex])
    },[currentIndex])

    return (
        <div className="carousel-overlay">
            <button className="carousel-close-button" onClick={onClose}><Icon icon="fa6-solid:arrow-left"/></button>
            { userType === "host" && <CarouselIcons mediaId={initialIndex} media={media} flag={flag} setInitialCarouselIndex={setInitialCarouselIndex} setDeleteId={setDeleteId} handlePrev={handlePrev} handleNext={handleNext}/> }
            <button className="prev-button" onClick={handlePrev}>‹</button>
                <div className="carousel-content">
                    <div className="media-display">
                        {currentMedia.type === 'image' ? (
                            <CarouselImageDisplay src={currentMedia.item.compressedUrl ? currentMedia.item.compressedUrl : currentMedia.item.url} />
                        ) : (
                            <CarouselVideoPlayer src={currentMedia.item.url} />
                        )}
                    </div>
                </div>
            <button className="next-button" onClick={handleNext}>›</button>
        </div>
    );
};

export default Carousel;
