import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import logo from '../../Constant/logo2.png';
import divider from "../../Constant/Auth_Divider.svg"
import {eventDetails, PASSWORD, USERNAME} from "../../Constant/constants";
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Button from "../Utils/Button";
import "./LoginPage.css"
import {handleSendOtp} from "../../Scripts/Otp";

function LoginPage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [phone, setPhone] = useState('');
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (id) {
            sessionStorage.setItem('eventID', id);
        }
    }, [id]);

    useEffect(() => {
        const fetchEventData = async () => {
            if (id) {
                const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
                try {
                    const response = await fetch(`${eventDetails}${id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': basicAuth,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (response.ok) {
                        const jsonData = await response.json();
                        setData(jsonData); // Sets the fetched JSON to `data`
                    } else {
                        console.error("Failed to fetch event data:", response.statusText);
                    }
                } catch (error) {
                    console.error("Error fetching event data:", error);
                }
            }
        };

        fetchEventData();
    }, [id, USERNAME, PASSWORD, eventDetails]);


    const formatDate = (inputDate) => {
        if (!inputDate) return

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const dateParts = inputDate.split("-")
        const year = dateParts[0]
        const month = months[parseInt(dateParts[1], 10) - 1]
        const day = parseInt(dateParts[2], 10)

        return `${day} ${month}, ${year}`
    }

    const sendOtp = async () => {
        setIsLoading(true)
        if (phone === "") {
            setIsLoading(false)
            return toast.error("Please enter your phone number")
        }
        const response = await handleSendOtp(phone)
        sessionStorage.setItem('phoneNumber', phone);
        sessionStorage.setItem('event', JSON.stringify(data));
        setIsLoading(false)
        if (response?.status === "OK") {
            // toast.success("Otp sent successfully");
            navigate(`/${id}/otp`);
        } else {
            toast.error("Failed to send OTP. Please check your phone number");
        }
    }

    const handlePhoneNumberChange = (value) => {
        setPhone(value);
    };

    return (
            <div className="login-container">
                <div className="image-div">
                    <img
                        src={
                            data?.body?.compressedCoverPhotoLink
                                ? data?.body?.compressedCoverPhotoLink
                                : data?.body?.coverPhotoLink
                        }
                        alt={data?.body?.name}
                    />
                </div>
                <div className="event-details-div">
                    <img id="logo" src={logo} alt="Logo" />
                    <div className="login-form-container">
                        <div className="event-name">{data?.body.name}</div>
                        <div className="event-date">{formatDate(data?.body.date)}</div>
                        <img src={divider} alt="divider" className="auth-divider"  />
                        <div className="page-title">Login</div>
                        <div className="page-description">We'll send you a code to verify your number.</div>
                    </div>
                    <div className="phone-input-div">
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={phone}
                            defaultCountry="IN"
                            onChange={(value) => handlePhoneNumberChange(value)}
                            international
                            className="phone-input"
                        />
                        <Button className="send-code-button" onClick={sendOtp} isLoading={isLoading}>
                            Send Code
                        </Button>
                    </div>
                </div>
            </div>
    )
}

export default LoginPage;
