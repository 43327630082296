import React, { forwardRef, useState, useEffect } from 'react';
import './PageThree.css';
import { useNavigate } from 'react-router-dom';
import defaultFolder from "../../../Assets/defaultFolder.svg";
import {toast} from "react-toastify";

const PageThree = forwardRef(({ eventId, folderData, backgroundImage }, ref) => {
    const navigate = useNavigate();
    const userType = sessionStorage.getItem('userType');
    const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
    const folder = {
        name: "All Files",
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 769);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (folder) => {
        if (userType === "guest") {
            if (folder.openToPublic) {
                navigate(`/${eventId}/albums/${encodeURIComponent(folder.id)}`, {
                    state: { folder }
                });
            } else {
                toast.error("You don't have permission to view this folder.");
            }
        } else {
            navigate(`/${eventId}/albums/${encodeURIComponent(folder.id)}`, {
                state: { folder }
            });
        }
    };

    const handleAllFilesClick = () => {
        navigate(`/${eventId}/albums/${encodeURIComponent("all")}`, {
            state: { folder }
        });
    };

    const renderDesktopLayout = () => {
        return folderData.reduce((rows, folder, index) => {
            if (index % 2 === 0) {
                const nextFolder = folderData[index + 1];
                const isOddRow = Math.floor(index / 2) % 2 === 0;
                const colClass1 = isOddRow ? "col-lg-8" : "col-lg-4";
                const colClass2 = isOddRow ? "col-lg-4" : "col-lg-8";

                rows.push(
                    <div key={index} className="row mb-4">
                        <div className={colClass1} onClick={() => handleClick(folder)}>
                            <div className="card custom-card" style={{ backgroundImage: `url(${folder.coverPhotoUrl})` }}>
                                <div className="card-body">
                                    <h5 className="card-title">
                                        {userType === "guest" && !folder.openToPublic && <i className="bi bi-lock-fill lock-icon"></i>}
                                        {folder.name}
                                    </h5>
                                </div>
                            </div>
                        </div>
                        {nextFolder && (
                            <div className={colClass2} onClick={() => handleClick(nextFolder)}>
                                <div className="card custom-card" style={{ backgroundImage: `url(${nextFolder.coverPhotoUrl})` }}>
                                    <div className="card-body">
                                        <h5 className="card-title">
                                            {userType === "guest" && !nextFolder.openToPublic && <i className="bi bi-lock-fill lock-icon"></i>}
                                            {nextFolder.name}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
            return rows;
        }, []);
    };

    const renderMobileLayout = () => {
        return folderData.map((folder, index) => (
            <div key={index} className="row mb-4">
                <div className="col-sm-12" onClick={() => handleClick(folder)}>
                    <div className="card custom-card" style={{ backgroundImage: `url(${folder.coverPhotoUrl})` }}>
                        <div className="card-body">
                            <h5 className="card-title">
                                {userType === "guest" && !folder.openToPublic && <i className="bi bi-lock-fill lock-icon"></i>}
                                {folder.name}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div ref={ref} id="page3" className="page-three-container" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <h1 className="gallery-heading">Our Gallery</h1>
            <div className="container mt-5">
                <div className="row mb-4">
                    <div className="col-sm-6" onClick={() => handleAllFilesClick()}>
                        <div className="card custom-card" style={{
                            backgroundImage: `url(${defaultFolder})`,
                        }}>
                            <div className="card-body">
                                <h5 className="card-title">
                                    All Files
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile ? renderMobileLayout() : renderDesktopLayout()}
            </div>
        </div>
    );
});

export default PageThree;
