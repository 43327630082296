import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "react-phone-number-input/style.css";
import logo from '../../Constant/logo2.png';
import divider from "../../Constant/Auth_Divider.svg"
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Button from "../Utils/Button";
import "./CreateProfilePage.css"
import {handleCreateProfile} from "../../Scripts/CreateProfile";
import {handleJoinEvent} from "../../Scripts/JoinEvent";

function CreateProfilePage() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const phoneNumber = sessionStorage.getItem('phoneNumber');
    const [isLoading, setIsLoading] = useState(true);
    const [createProfileResponse, setCreateProfileResponse] = useState(null)
    const eventDataString = sessionStorage.getItem('event');
    const [eventData, setEventData] = useState(null);

    useEffect(() => {
        if (eventDataString) {
            setEventData(JSON.parse(eventDataString));
            console.log(eventData);
        } else {
            console.log("No data found!");
        }
    }, [eventDataString]);

    const createProfile = async () => {
        if (firstName === "" || lastName === "") return toast.error("Please enter both first and last name")
        const response = await handleCreateProfile(firstName, lastName, phoneNumber)
        if (response.status === "OK") {
            sessionStorage.setItem('userId', response?.body?.id);
            await handleJoinEvent(id, response?.body?.id)
            navigate(`/${id}/user`);
        } else {
            console.log("in jsx")
            toast.error("Failed to create profile. Please try again later");
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const dateParts = inputDate.split("-")
        const year = dateParts[0]
        const month = months[parseInt(dateParts[1], 10) - 1]
        const day = parseInt(dateParts[2], 10)

        return `${day} ${month}, ${year}`
    }

    return (
        <div className="login-container">
            <div className="image-div">
                <img
                    src={
                        eventData?.body?.compressedCoverPhotoLink
                        ? eventData?.body?.compressedCoverPhotoLink
                        : eventData?.body?.coverPhotoLink
                    }
                    alt={eventData?.body?.name}
                    onLoad={() => setIsLoading(false)}
                />
            </div>
            <div className="event-details-div">
                <img id="logo" src={logo} alt="Logo" />
                <div className="login-form-container">
                    <div className="event-name">{eventData?.body.name}</div>
                    <div className="event-date">{formatDate(eventData?.body.date)}</div>
                    <img src={divider} alt="divider" className="auth-divider"  />
                    <div className="page-title">Create Profile</div>
                    <div className="page-description">Please enter your first and last name.</div>
                </div>
                <div className="input-div">
                    <input
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="text-input"
                    />
                    <input
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="text-input"
                    />
                    <Button className="send-code-button" onClick={createProfile}>
                        Create Profile
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default CreateProfilePage;
