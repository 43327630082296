import React, { useEffect, useState, useContext  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './HostGuestPage.css';
import leftImage from '../../Constant/LoginPageImage.jpg';
import logo2 from '../../Constant/logo2.png';
import { AuthContext } from '../../Authentication/AuthContext';
import logo from "../../Constant/logo2.png";
import divider from "../../Constant/Auth_Divider.svg";
import Button from "../Utils/Button";

function HostGuestPage() {
    const navigate = useNavigate();
    const [userType, setUserType] = useState('guest');
    const data = sessionStorage.getItem('event');
    const [eventData, setEventData] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        if (data) {
            setEventData(JSON.parse(data));
        } else {
            console.log("No data found!");
        }
    }, [data]);

    const handleUserTypeChange = (value) => {
        setUserType(value)
        sessionStorage.setItem("userType", value)
    
        if (value === 'guest') {
            navigate(`/${id}/landing`);
        } else {
            navigate(`/${id}/verify`);
        }
    };

    const formatDate = (inputDate) => {
        if (!inputDate) return

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const dateParts = inputDate.split("-")
        const year = dateParts[0]
        const month = months[parseInt(dateParts[1], 10) - 1]
        const day = parseInt(dateParts[2], 10)

        return `${day} ${month}, ${year}`
    }

    return (
        <div className="login-container">
            <div className="image-div">
                <img
                    src={
                        eventData?.body?.compressedCoverPhotoLink
                            ? eventData?.body?.compressedCoverPhotoLink
                            : eventData?.body?.coverPhotoLink
                    }
                    alt={eventData?.body?.name}
                />
            </div>
            <div className="event-details-div">
                <img id="logo" src={logo} alt="Logo" />
                <div className="login-form-container">
                    <div className="event-name">{eventData?.body?.name}</div>
                    <div className="event-date">{formatDate(eventData?.body?.date)}</div>
                    <img src={divider} alt="divider" className="otp-auth-divider"  />
                    <div className="page-title">Who are you ?</div>
                    <div className="page-description">Select your category for the event</div>
                </div>
                <div className="vertical-btn-group">
                    <button
                        type="button"
                        className={`btn ${userType === 'host' ? 'btn-host active' : 'btn-host inactive'}`}
                        onClick={() => handleUserTypeChange('host')}
                    >
                        I am Host
                    </button>
                    <button
                        type="button"
                        className={`btn ${userType === 'guest' ? 'btn-guest active' : 'btn-guest inactive'}`}
                        onClick={() => handleUserTypeChange('guest')}
                    >
                        I am Guest
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HostGuestPage;

