import React, { useEffect, useState } from "react";
import Carousel from "../Carousel/Carousel";
import RenderMediaRows from "../../Utils/RenderMediaRows";
import './MediaRender.css';

function MediaRender({ media, flag, deleteId, setDeleteId, selectedMedia, onSelect }) {
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [initialCarouselIndex, setInitialCarouselIndex] = useState(0);
    const [lastClickTime, setLastClickTime] = useState(0);

    const handleMediaClick = (mediaId, index) => {
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - lastClickTime;

        if (timeDiff < 300) { // Double click
            setInitialCarouselIndex(index);
            setCarouselOpen(true);
        } else { // Single click
            onSelect(mediaId);
        }
        setLastClickTime(currentTime);
    };

    return (
        <div className="album-content">
            <RenderMediaRows
                media={media}
                carouselOpen={carouselOpen}
                flag={flag}
                setDeleteId={setDeleteId}
                setInitialCarouselIndex={setInitialCarouselIndex}
                setCarouselOpen={setCarouselOpen}
                selectedMedia={selectedMedia}
                handleMediaClick={handleMediaClick}
            />
            {carouselOpen && (
                <Carousel
                    media={media}
                    initialIndex={initialCarouselIndex}
                    setInitialCarouselIndex={setInitialCarouselIndex}
                    onClose={() => setCarouselOpen(false)}
                    setDeleteId={setDeleteId}
                    flag={flag}
                />
            )}
        </div>
    );
}

export default MediaRender;