import {PASSWORD, sendOtp, USERNAME, verifyOtp} from "../Constant/constants";
import {toast} from "react-toastify";

export const handleSendOtp = async (phoneNumber) => {

    const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);

    const body = {
        phoneNumber: phoneNumber
    };

    try{
        const response = await fetch(sendOtp, {
            method: 'POST',
            headers: {
                'Authorization': basicAuth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
        const data = await response.json();
        // if (!response.ok) {
        //     toast.error("Failed to send OTP. Please verify your phone number and try again");
        // }
        return data;
    }catch(e){
        console.log("Failed to send OTP. Please verify your phone number and try again");
        // toast.error("Failed to send OTP. Please verify your phone number and try again");
    }
};

export const handleVerifyOtp = async (phoneNumber, otp) => {

    const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);

    const body = {
        phoneNumber: phoneNumber,
        otp: otp
    };

    try{
        const response = await fetch(verifyOtp, {
            method: 'POST',
            headers: {
                'Authorization': basicAuth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();
        // if (!response.ok) {
        //     toast.error("Failed to verify OTP. Please try again");
        // }
        return data;
    }catch(e){
        console.log("Failed to verify OTP. Please try again", e)
        // toast.error("Failed to verify OTP. Please try again");
    }
};