import React, {useEffect, useRef, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import "react-phone-number-input/style.css";
import logo from '../../Constant/logo2.png';
import divider from "../../Constant/Auth_Divider.svg"
import 'react-toastify/dist/ReactToastify.css';
import Button from "../Utils/Button";
import "./OtpPage.css"
import {handleVerifyOtp} from "../../Scripts/Otp";
import {handleJoinEvent} from "../../Scripts/JoinEvent";
import {toast} from "react-toastify";

const OtpPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const data = sessionStorage.getItem('event');
    const phoneNumber = sessionStorage.getItem('phoneNumber');
    const [eventData, setEventData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Focus first input on mount
        if (inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, []);

    useEffect(() => {
        if (data) {
            setEventData(JSON.parse(data));
            console.log(eventData);
        } else {
            console.log("No data found!");
        }
    }, [data]);

    const handleChange = (index, value) => {
        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        // Move to next input if value is entered
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        // Move to previous input on backspace if current input is empty
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const verifyOtp = async () => {
        setIsLoading(true)
        const otp = code.join('');
        const response = await handleVerifyOtp(phoneNumber, otp)
        console.log(response)

        if (response?.status === "OK") {
            setIsLoading(false)
            // toast.success("Otp verified successfully");
            if (response.body) { //Phone number registered
                await handleJoinEvent(id, response.body.id)
                navigate(`/${id}/user`);
            } else { // new user
                // console.log("verifyOtpResponse new user")
                navigate(`/${id}/profile`);
            }
        } else {
            setIsLoading(false)
            toast.error("Failed to verify OTP. Please try again");
        }
    }

    const formatDate = (inputDate) => {
        if (!inputDate) return

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const dateParts = inputDate.split("-")
        const year = dateParts[0]
        const month = months[parseInt(dateParts[1], 10) - 1]
        const day = parseInt(dateParts[2], 10)

        return `${day} ${month}, ${year}`
    }

    return (
        <div className="otp-login-container">
            <div className="otp-image-div">
                <img
                    src={
                        eventData?.body?.compressedCoverPhotoLink
                        ? eventData?.body?.compressedCoverPhotoLink
                        : eventData?.body?.coverPhotoLink
                }
                    alt={eventData?.body?.name}
                />
            </div>
            <div className="otp-event-details-div">
                <img id="logo" src={logo} alt="Logo" />
                <div className="otp-login-form-container">
                    <div className="otp-event-name">{eventData?.body?.name}</div>
                    <div className="otp-event-date">{formatDate(eventData?.body?.date)}</div>
                    <img src={divider} alt="divider" className="otp-auth-divider"  />
                    <div className="otp-page-title">Verification Code</div>
                    <div className="otp-page-description">Please enter the verification code sent to your number.</div>
                </div>
                <div className="code-input-div">
                    <div className="code-input">
                        {code.map((digit, index) => (
                            <input
                                id="otp-input"
                                key={index}
                                ref={(el) => (inputRefs.current[index] = el)}
                                type="tel" // Changed from text to tel
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                inputMode="numeric" // Explicitly tell browsers this input expects numeric values
                            />
                        ))}
                    </div>
                    <Button className="otp-page-button" onClick={verifyOtp} isLoading={isLoading}>
                        Verify Code
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default OtpPage;
