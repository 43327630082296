import {joinEvent, PASSWORD, sendOtp, USERNAME} from "../Constant/constants";
import {toast} from "react-toastify";

export const handleJoinEvent = async (eventId, userId) => {

    const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
    const apiUrl = `${joinEvent}?eventId=${encodeURIComponent(eventId)}&userId=${encodeURIComponent(userId)}`;
    try{
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': basicAuth,
                'Content-Type': 'application/json',
            }
        });

        if (response.ok) {
            const data = await response.json();
            // console.log(data)
            // toast.success("Event Joined successfully");
        } else {
            toast.error("Failed to join event. Please try again later");
        }
    }catch(e){
        toast.error("Failed to join event. Please try again later");
    }
};