import React, {useEffect, useState} from 'react';
import { Routes, Route, Navigate, useParams} from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';

import LandingPage from './Components/LandingPage/LandingPage/landingPage';
import Albums from './Components/Albums/Albums/Albums';
// import HostGuestPage from './Components/Login/HostGuestPage'
import HostVerificationPage from './Components/Login/HostVerificationPage';
import { AuthProvider } from './Authentication/AuthContext';
import ProtectedRoute from './Authentication/ProtectedRoute';
import PhysicalAlbum from './Components/PhysicalAlbum/PhysicalAlbum';
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from "./Components/Login/LoginPage";
import OtpPage from "./Components/Otp/OtpPage";
import HostGuestPage from "./Components/User/HostGuestPage";
import CreateProfilePage from "./Components/Profile/CreateProfilePage";
import {ToastContainer} from "react-toastify";
import {eventDetails, PASSWORD, USERNAME} from "./Constant/constants";

const DynamicNavigate = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [navigateTo, setNavigateTo] = useState(null); // Track navigation target

  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.setItem('eventID', id);

      if (id) {
        const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
        try {
          const response = await fetch(`${eventDetails}${id}`, {
            method: 'GET',
            headers: {
              'Authorization': basicAuth,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const jsonData = await response.json();
            setData(jsonData);

            if (jsonData?.body?.dataCollectionEnabled) {
              sessionStorage.setItem('event', JSON.stringify(jsonData));
              setNavigateTo(`/${id}/login`);
            } else {
              sessionStorage.setItem('event', JSON.stringify(jsonData));
              setNavigateTo(`/${id}/user`);
            }
          } else {
            console.error("Failed to fetch event data:", response.statusText);
          }
        } catch (error) {
          console.error("Error fetching event data:", error);
        }
      }
    };

    fetchData();
  }, [id]);

  // Navigate when ready
  if (navigateTo) {
    return <Navigate to={navigateTo} replace />;
  }
};


function App() {
  return (
      <AuthProvider>
        <ToastContainer position="top-right" autoClose={3000} />
          <Routes>
            <Route path="/:id" element={<DynamicNavigate />}/>
            <Route path="/:id/login" element={<LoginPage />}/>
            <Route path="/:id/otp" element={<OtpPage />} />
            <Route path="/:id/profile" element={<CreateProfilePage />}/>
            <Route path="/:id/user" element={<HostGuestPage />}/>
            <Route element={<ProtectedRoute />}>
              <Route path="/:id/verify" element={<HostVerificationPage />} />
              <Route path="/:id/landing" element={<LandingPage />} />
              <Route path="/:id/albums/:albumId" element={<Albums />} />
              <Route path="/:id/physical-albums" element={<PhysicalAlbum />} />
            </Route>
          </Routes>
      </AuthProvider>
  );
}

export default App;
