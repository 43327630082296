import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './PageTwoContent.css';
import backgroundImage from '../../../Assets/backgroundImage.png';

import Header from '../../Header/Header';
import PageThree from '../PageThree/PageThree';
import PageTwo from '../PageTwo/PageTwo';

function PageTwoContent({ itemName, coverPhotoURL, description, eventId, folderData }) {
    const page2Ref = useRef(null);
    const page3Ref = useRef(null);
    const location = useLocation();


    useEffect(() => {
        const hash = location.hash;
        if (hash === '#page2' && page2Ref.current) {
          page2Ref.current.scrollIntoView({ behavior: 'smooth' });
        } else if (hash === '#page3' && page3Ref.current) {
          page3Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
      },);

    return (
        <div className="page-two-content">
            <Header />
            <div className="divider"></div>
            <PageTwo ref={page2Ref} coverPhotoURL={coverPhotoURL} itemName={itemName} description={description} backgroundImage={backgroundImage}/>
            <PageThree ref={page3Ref} eventId={eventId} folderData={folderData} backgroundImage={backgroundImage}/>
        </div>
    );
}

export default PageTwoContent;
