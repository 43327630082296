import React from "react";
import { Oval } from "react-loader-spinner";
import "./Button.css"; // Import custom CSS file

function Button({
                    children,
                    hasCustomBg = false,
                    onClick,
                    isTransparent = false,
                    className = "",
                    type = "submit",
                    disabled = false,
                    isLoading = false,
                }) {
    return (
        <button
            className={`custom-button ${isLoading ? "loading" : ""} ${
                hasCustomBg ? "" : isTransparent ? "transparent" : "default-bg"
            } ${disabled ? "disabled" : ""} ${className}`}
            onClick={onClick}
            type={type}
            disabled={isLoading || disabled}
        >
            {isLoading ? (
                <Oval
                    visible={true}
                    height="20"
                    width="20"
                    color="#000"
                    ariaLabel="oval-loading"
                    secondaryColor="#000" // Stroke color
                    strokeWidth={3}
                />
            ) : (
                children
            )}
        </button>
    );
}

export default Button;
