import React from "react";
import { ImageDisplay } from "../Albums/Images/Image";
import { VideoPlayer } from "../Albums/VedioPlayer/VideoPlayer";

function RenderMediaRows({ media, carouselOpen, flag, setDeleteId, setInitialCarouselIndex, setCarouselOpen, selectedMedia, handleMediaClick }) {
    const getCount = (media) => {
        return media.reduce((count, item) => {
            return item.type === 'video' ? count + 1 : count;
        }, 0);
    };

    const rows = [];
    let remainingVideos = getCount(media);
    let count = 3;

    const render = () => {
        for (let i = 0; i < media.length; i += count) {
            if (remainingVideos > 0) {
                count = 3;
                const rowItems = media.slice(i, i + count);

                if (rows.length % 2 === 0) {
                    // Odd row: 1 video, 2 images
                    const video = rowItems.find(item => item.type === 'video');
                    const images = rowItems.filter(item => item.type === 'image');
                    rows.push(
                        <div key={`row-${i}`} className="media-row">
                            <div className={`media-half ${selectedMedia.includes(video.item.id) ? 'selected' : ''}`}>
                                <VideoPlayer
                                    id={video.item.id}
                                    mediaId={i}
                                    carouselOpen={carouselOpen}
                                    src={video.item.url}
                                    flag={flag}
                                    setDeleteId={setDeleteId}
                                    handleMediaClick={() => handleMediaClick(video.item.id, i)}
                                />
                            </div>
                            <div className="media-half images-right">
                                {images.map((image, index) => (
                                    <div
                                        key={index}
                                        className={`media-wrapper ${selectedMedia.includes(image.item.id) ? 'selected' : ''}`}
                                    >
                                        <ImageDisplay
                                            mediaId={i + index + 1}
                                            id={image.item.id}
                                            src={image.item.compressedUrl || image.item.url}
                                            alt=""
                                            className="media-image-half"
                                            handleMediaClick={() => handleMediaClick(image.item.id, i + index + 1)}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                } else {
                    // Even row logic similar to odd row...
                    // Same pattern as above but with reversed layout
                }
                remainingVideos--;
            } else {
                count = 4;
                const rowItems = media.slice(i, i + count);
                rows.push(
                    <div key={`row-${i}`} className="media-row media-row-images">
                        {rowItems.map((item, index) => (
                            <div
                                key={index}
                                className={`media-quarter ${selectedMedia.includes(item.item.id) ? 'selected' : ''}`}
                            >
                                <ImageDisplay
                                    mediaId={i + index}
                                    id={item.item.id}
                                    src={item.item.compressedUrl || item.item.url}
                                    alt=""
                                    className="media-image-quarter"
                                    handleMediaClick={() => handleMediaClick(item.item.id, i + index)}
                                    flag={flag}
                                    setDeleteId={setDeleteId}
                                />
                            </div>
                        ))}
                    </div>
                );
            }
        }
        return rows;
    };

    return <div>{render()}</div>;
}

export default RenderMediaRows;