import { USERNAME, PASSWORD, addToPhysicalALbum } from "../Constant/constants";
import {toast} from "react-toastify";

const handleAddPhysicalAlbumClick = async (id) => {

    const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);

    const body = {
        addToPhysicalAlbum: true,
        fileIds: [id],
    };

    try{
        const response = await fetch(addToPhysicalALbum, {
            method: 'POST',
            headers: {
                'Authorization': basicAuth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        if (response.ok) {
            toast.success("Photo added to physical album");
        } else {
            toast.error("Failed to add photo to physical album");
        }
    
    }catch(e){
    }
};

export default handleAddPhysicalAlbumClick;