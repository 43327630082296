import {createProfile, PASSWORD, USERNAME} from "../Constant/constants";
import {toast} from "react-toastify";

export const handleCreateProfile = async (firstName, lastName, phoneNumber) => {

    const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
    const body = {
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phoneNumber
    };
    try{
        const response = await fetch(createProfile, {
            method: 'POST',
            headers: {
                'Authorization': basicAuth,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });

        const data = await response.json();
        // if (!response.ok) {
        //     toast.error("Failed to create profile. Please try again later");
        // }
        return data;
    }catch(e){
        console.log("Failed to create profile. Please try again later");
        // toast.error("Failed to create profile. Please try again later");
    }
};