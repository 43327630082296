import React, {useEffect, useState} from 'react';
import './LoginPage.css';
import { USERNAME,  PASSWORD, pincodeVerify } from '../../Constant/constants';
import leftImage from '../../Constant/LoginPageImage.jpg';
import logo2 from '../../Constant/logo2.png';
import { useNavigate } from 'react-router-dom';
import {toast} from "react-toastify";
import logo from "../../Constant/logo2.png";
import divider from "../../Constant/Auth_Divider.svg";

function HostVerificationPage() {
    const [pincode, setPinCode] = useState('');
    const [isDirty, setIsDirty] = useState(false); 
    const eventId = sessionStorage.getItem('eventID')
    const data = sessionStorage.getItem('event');
    const [eventData, setEventData] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (data) {
            setEventData(JSON.parse(data));
        } else {
            console.log("No data found!");
        }
    }, [data]);

    const handleInputChange = (e) => {
        const newValue  = e.target.value;
        setPinCode(newValue)
        setIsDirty(newValue.length > 0)
    };

    const handleClick = async (event) => {
        event.preventDefault();
        try{
            const basicAuth = 'Basic ' + btoa(USERNAME + ':' + PASSWORD);
            const response = await fetch(pincodeVerify, {
                method: 'POST',
                headers: {
                    'Authorization': basicAuth,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({pincode, eventId})

            })
            const responseData = await response.json()
            if(responseData.status === "OK"){
                navigate(`/${eventId}/landing`)
            } else {
                toast.error('Incorrect pincode. Please try again.');
            }
        }catch(error){
        }
    };

    const formatDate = (inputDate) => {
        if (!inputDate) return

        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ]
        const dateParts = inputDate.split("-")
        const year = dateParts[0]
        const month = months[parseInt(dateParts[1], 10) - 1]
        const day = parseInt(dateParts[2], 10)

        return `${day} ${month}, ${year}`
    }

    return (
        <div className="login-container">
            <div className="image-div">
                <img
                    src={
                        eventData?.body?.compressedCoverPhotoLink
                            ? eventData?.body?.compressedCoverPhotoLink
                            : eventData?.body?.coverPhotoLink
                    }
                    alt={eventData?.body?.name}
                />
            </div>
            <div className="event-details-div">
                <img id="logo" src={logo} alt="Logo" />
                <div className="login-form-container">
                    <div className="event-name">{eventData?.body?.name}</div>
                    <div className="event-date">{formatDate(eventData?.body?.date)}</div>
                    <img src={divider} alt="divider" className="otp-auth-divider"  />
                    <div className="page-title">Who are you ?</div>
                    <div className="page-description">Select your category for the event</div>
                </div>
                <div className="input-group">
                    <input
                        type="text"
                        className={`form-input ${isDirty ? 'active' : 'inactive'}`}
                        value={pincode}
                        onChange={handleInputChange}
                        placeholder="Eg: 4444"
                    />
                </div>
                <div className="vertical-btn-group">
                    <button
                        type="button"
                        className={`btn ${isDirty ? 'btn-confirm active' : 'btn-confirm inactive'}`}
                        onClick={handleClick}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HostVerificationPage;
