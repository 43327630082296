import React, { forwardRef } from 'react';
import gifURL from '../../../Constant/down-arrow.gif';
import './PageTwo.css';

const PageTwo = forwardRef(({ coverPhotoURL, itemName, description, backgroundImage }, ref) => (
    <div
        className="page-two-wrapper"
        ref={ref}
        style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
    >
        <div id="page2" className="page-two-container">
            <div className="description-container">
                <div className="goldbg" />
                <p>
                    “{description}“
                </p>
                <div className="goldbg" />
            </div>
        </div>
        {gifURL && <img src={gifURL} alt="GIF" id="arrow-gif-page2" />}
    </div>
));

export default PageTwo;
