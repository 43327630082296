import React, { useEffect, useState } from 'react';
import Header from '../Header/Header';
import MediaRender from '../Albums/MediaRender/MediaRender';
import './PhysicalAlbum.css';
import { fetchPhysicalAlbums } from '../../Scripts/FetchAlbums';
import upArrow from "../../Constant/up-arrow.gif";
import JSZip from "jszip";
import {Icon} from "@iconify-icon/react";
import { saveAs } from "file-saver";

const PhysicalAlbum = () => {
    const [media, setMedia] = useState([]);
    const [progress, setProgress] = useState(0); // Track progress
    const [currentPart, setCurrentPart] = useState(0);
    const [totalParts, setTotalParts] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false); // Track download state
    const [deleteId, setDeleteId] = useState(null)
    const [showScrollToTop, setShowScrollToTop] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);
    const userType = sessionStorage.getItem('userType');
    const id = sessionStorage.getItem("eventID")
    const [selectedMedia, setSelectedMedia] = useState([]);
    const [chunkSize, setChunkSize] = useState(50);
    const [error, setError] = useState(null);
    const event = sessionStorage.getItem('event');
    const [downloadEnabled, setDownloadEnabled] = useState(true);

    useEffect(() => {
        fetchPhysicalAlbums(id, setMedia);
        setDeleteId(null)
        let eventJson = JSON.parse(event)
        if (eventJson?.body?.downloadEnabled) {
            setDownloadEnabled(true)
        } else {
            setDownloadEnabled(false)
        }
    }, []);

    useEffect(() => {
      let newMedia = media.filter((_, index) => index !== deleteId);
      setMedia(newMedia)
      setDeleteId(null)
    }, [deleteId]);

    useEffect(() => {
      const handleScroll = () => {
          if (window.scrollY === 0) {
              setShowScrollToTop(false);
          } else if (window.scrollY < lastScrollY) {
              setShowScrollToTop(true);
          } else {
              setShowScrollToTop(false);
          }
          setLastScrollY(window.scrollY);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [lastScrollY]);

  const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSelect = (mediaId) => {
      if (userType === "host") {
          setSelectedMedia(prev => {
              if (prev.includes(mediaId)) {
                  return prev.filter(id => id !== mediaId);
              }
              return [...prev, mediaId];
          });
      }
  };

    const handleSelectAll = () => {
        if (userType === "host") {
            if (selectedMedia.length === media.length) {
                setSelectedMedia([]);
            } else {
                const allMediaIds = media.map(item => item.item.id);
                setSelectedMedia(allMediaIds);
            }
        }
    };
    const processAndDownloadChunk = async (mediaChunk, partNumber, totalParts) => {
        const zip = new JSZip();
        setCurrentPart(partNumber);
        setProgress(0);

        try {
            for (let i = 0; i < mediaChunk.length; i++) {
                const item = mediaChunk[i];
                const response = await fetch(item.item.url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch ${item.item.name}`);
                }
                const blob = await response.blob();
                zip.file(item.item.name || `file-${item.item.id}.jpg`, blob);

                setProgress(Math.round(((i + 1) / mediaChunk.length) * 100));
            }

            const zipBlob = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {
                    level: 6
                }
            });

            saveAs(zipBlob, `media-files-part${partNumber+1}-of-${totalParts}.zip`);
            return true;
        } catch (error) {
            console.error(`Error processing part ${partNumber + 1}:`, error);
            throw error;
        }
    };

    const handleDownload = async () => {
        setError(null);
        const selectedMediaIds = selectedMedia;
        const mediaToDownload = media.filter(item => selectedMediaIds.includes(item.item.id));

        if (mediaToDownload.length === 0) {
            setError("No media selected for download.");
            return;
        }

        setIsDownloading(true);
        const parts = [];

        // Split media into chunks
        for (let i = 0; i < mediaToDownload.length; i += chunkSize) {
            parts.push(mediaToDownload.slice(i, i + chunkSize));
        }

        setTotalParts(parts.length);

        try {
            for (let i = 0; i < parts.length; i++) {
                await processAndDownloadChunk(parts[i], i, parts.length);
                // Small delay between downloads to prevent overwhelming the browser
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        } catch (error) {
            setError("An error occurred during download. Please try again with fewer files.");
            setChunkSize(Math.max(2, chunkSize - 2));
        } finally {
            setIsDownloading(false);
            setCurrentPart(0);
            setProgress(0);
        }
    };

  return (
    <div className='physical-albums'>
      <Header />
      <div className="divider"></div>
      <h2 className='heading'>Physical Album</h2>
        {downloadEnabled && selectedMedia.length > 0 && (
            <div className="selection-toolbar">
                <span>{selectedMedia.length} selected</span>
                <button onClick={handleSelectAll}>Select All</button>
                <div className="toolbar-icons">
                    <Icon
                        onClick={handleDownload}
                        icon="prime:download"
                        className="icon-album"
                    />
                </div>
                <button onClick={() => setSelectedMedia([])}>Cancel</button>
            </div>
        )}
        {isDownloading && (
            <div className="overlay">
                <div className="progress-modal">
                    <h3>Downloading Part {currentPart + 1} of {totalParts}</h3>
                    <div className="progress-bar">
                        <div
                            className="progress-bar-fill"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <p>{progress}%</p>
                </div>
            </div>
        )}
        {media.length > 0 ? (
            <div>
              <MediaRender media={media} deleteId={deleteId} setDeleteId={setDeleteId} selectedMedia={selectedMedia} onSelect={handleSelect} flag="physicalAlbum"/>
              {showScrollToTop && (
                <img 
                    src={upArrow} 
                    alt="Scroll to Top" 
                    className="scroll-to-top-gif" 
                    onClick={scrollToTop}
                />
            )}
            </div>
        ) : (
            <p>No files in physical album</p>
        )}
    </div>
  );
};

export default PhysicalAlbum;
