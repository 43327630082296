// config.js
const USERNAME = 'IFE';
const PASSWORD = 'IFEbe@18';

export const eventDetails = 'https://www.be.itsforever.in:433/events/'
const pageOneAndTwo = 'https://www.be.itsforever.in:433/events/'
const pageThree = 'https://www.be.itsforever.in:433/folder/list'
const pincodeVerify = 'https://www.be.itsforever.in:433/gallery/pin/verify'
const getAlbums = 'https://www.be.itsforever.in:433/file/folder/list/gallery'
const getAllFiles = 'https://www.be.itsforever.in:433/file/list'
const addToPhysicalALbum = 'https://www.be.itsforever.in:433/file/update/physical-album'
const sendOtp = 'https://www.be.itsforever.in:433/auth/verifyPhone/sendOtp'
const verifyOtp = 'https://www.be.itsforever.in:433/auth/verifyPhone/compareOtp'
const getPhysicalAlbums = "https://www.be.itsforever.in:433/file/physical-album"
const joinEvent = 'https://www.be.itsforever.in:433/events/join'
const createProfile = 'https://www.be.itsforever.in:433/user/createProfile'

export { USERNAME, PASSWORD, pageOneAndTwo, pageThree, pincodeVerify, getAlbums, addToPhysicalALbum, getPhysicalAlbums, getAllFiles, sendOtp, verifyOtp, joinEvent, createProfile };
